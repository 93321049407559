import React from "react";
import AOS from 'aos';
import { Link } from 'react-router-dom';

AOS.init();
const ProjectsPage = () => {
    return (
      <div className="page-content">
        <div className="jumbotron jumbotron-fluid min-vh-100 m-0" id="projectsPage">
          {/* <div className="container container-fluid p-5">
            <h1 className="display-4 pb-5">Some of my projects...</h1>
          </div> */}
          <div className="container">
            <div className="tab-content gallery mt-5">
              <div className="tab-pane active">
                <div className="ml-auto mr-auto">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                       
                          <figure className="cc-effect"><img src={require('./Images/blindsight.jpg')} height = "400px"alt=""/>
                            <figcaption>
                              <div className="h4">Rasperry Pi Server</div>
                              <p>A Rasperry Pi device turned into a server running Raspbian. It was made to be able to be used as a extra storage over the network file manager.</p>
                            </figcaption>
                          </figure>
                        
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                       
                          <figure className="cc-effect"><img src={require('./Images/adblock.jpg')} height = "400px" alt=""/>
                            <figcaption>
                              <div className="h4">Rasperry Pi Ad Blocker</div>
                              <p>A Rasperry Pi that would connect to your internet and block ads from the router itself.</p>
                            </figcaption>
                          </figure>
                        
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                         
                          <figure className="cc-effect"><img src={require('./Images/chatbot.jpg')} height = "400px"  alt=""/>
                            <figcaption>
                              <div className="h4">Atom Chatbot</div>
                              <p>A Chatbot created for the CUNY NYCCT that was used for new students to ask general questions. Such as, where is the Bursar?, What room is Professor Pinto's office?,What are Professor Marcus's office hours?</p>
                            </figcaption>
                          </figure>
                     
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        
                          <figure className="cc-effect"><img src={require('./Images/bc.jpg')} height = "400px" alt=""/>
                            <figcaption>
                              <div className="h4">AI: Breast Cancer Prediction</div>
                              <p>An AI created to record results of a patient and scan against other records of patients to predict if the user has benign or malignant breast cancer.</p>
                            </figcaption>
                          </figure>
                        
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        
                          <figure className="cc-effect"><img src={require('./Images/gamelogo.jpg')}height = "400px"  alt=""/>
                            <figcaption>
                              <div className="h4">Atomroid</div>
                              <p> A game which consists of 3 game modes. Colorroid, where asteroid would fall from the sky and the spaceship has to shoot them based on color,
                                AtomDodge, where bullets would be falling and the spaceship would have to dodge the bullets, and One in the chamber one bullet per asteroid.
                              </p>
                            </figcaption>
                          </figure>
                      
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                        
                          <figure className="cc-effect"><img src={require('./Images/gamelogo.jpg')} height = "400px" alt=""/>
                            <figcaption>
                              <div className="h4">Flappy Asteriod</div>
                              <p>Another game created to mock the famous game flappy bird. This game has asteroids comming at the space ship and tapping
                                 on the screen would make the spaceship fly similar to the bird in flappy bird.</p>
                            </figcaption>
                          </figure>
                     
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="cc-porfolio-image img-raised" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
                       
                          <figure className="cc-effect"><img src={require('./Images/car.jpg')} height = "400px" alt=""/>
                            <figcaption>
                              <div className="h4">Arduino Car/Skateboard</div>
                              <p>Using arduino software, a toy car was built using hardware created from a 3D printer.
                                Once the motors were developed and working the same software was used to add motors to a motor skateboard. </p>
                            </figcaption>
                          </figure>
                        
                      </div>
                    </div>
                    
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
export default ProjectsPage;
