import React from "react";
import AOS from 'aos';
import "bootstrap/dist/js/bootstrap.bundle.min";
import './experience.css';
import './main.css';
import './aos.css';
import './aboutStyleCSS.css';
import './bootstrap.min.css';

AOS.init();

const Awards = () => {
    return (
      <div>
        <div className="page-content">
          <div className="section" style={{paddingBottom:0}}>
            <div className="jumbotron jumbotron-fluid min-vh-100 m-0" id="awards">
              <div className="container cc-reference">
                <h5 className="display-4 mb-5 text-center">Awards</h5>
                <div id="carouselExampleIndicators" className="carousel slide carousel-fade" data-ride="carousel" style={{justifyContent: "center", maxWidth:"700px", margin:"auto"}}>
                  <ol className="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                    
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="card" style={{padding:0, paddingTop: "50px"}}>
                        <img src={require('./Images/nycct2.jpeg')} alt="" style={{display: "flex", justifyContent: "center", maxWidth:"300px", margin:"auto"}}/>  
                        <div className="card-body" style={{justifyContent: "center", margin:"auto", minHeight:"350px"}}>
                          <h5 className="card-title" style={{paddingTop: "20px", marginRight:"0px", marginLeft:"0px"}}>New York University - NYCCT</h5>
                          <ul className="card-text" data-aos="fade-down">
                            <li className="list-group-item">Emerging Scholars Awards</li>
                            <li className="list-group-item">National Society Of Collegiate Scholars </li>
                            <li className="list-group-item">Hillel Leadership Award</li>
                            <li className="list-group-item">Dean's List 2015-2019</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  
                    <div className="carousel-item">
                      <div className="card" style={{padding:0, paddingTop: "50px"}}>
                        <img src={require('./Images/or.jpg')} alt="" style={{justifyContent: "center", maxWidth:"300px", margin:"auto"}}/>  
                        <div className="card-body" style={{justifyContent: "center", margin:"auto", minHeight:"350px"}}>
                          <h5 className="card-title" style={{paddingTop: "20px", marginRight:"0px", marginLeft:"0px"}}>Ohr Hatorah High School</h5>
                          <ul className="card-text" data-aos="fade-down" >
                            <li className="list-group-item">School Service Award</li>
                            <li className="list-group-item">Advanced Regents Diploma</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a className="carousel-control left carousel-control-prev" href="#carouselExampleIndicators" data-slide="prev"></a>
                  <i className="fa fa-angle-left"></i>
                  <i className="fa fa-angle-left"></i>
                  <div align="right" style={{float:"right"}}>
                    <a className="carousel-control right carousel-control-next" href="#carouselExampleIndicators" data-slide="next" style={{alignText: "right"}}></a>
                    <i className="fa fa-angle-right"></i>
                    <i className="fa fa-angle-right"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
export default Awards;
