import React from "react";
import AOS from 'aos';
import "bootstrap/dist/js/bootstrap.bundle.min";
import './experience.css';
import './main.css';
import './aos.css';
import './aboutStyleCSS.css';
import './bootstrap.min.css';

AOS.init();

const Experience = () => {
  return (
    <div>
      <div className="page-content">
        <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0" id="experience">
          <div className="container cc-experience">
            <h5 className="display-4 mb-5 text-center">Work Experience</h5>

            <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
              <div className="row no-gutters">
                <div className="col-md-3">
                  <img src={require('./Images/Bentex.jpg')} style={{ marginTop: "35%"}} width="50%" height="50%" className="card-img" alt="..."/>
                  <div className="card-img-overlay cc-experience-header d-flex">
                    <div className="my-auto mx-auto text-center">
                      <p>March 2022 - Present</p>
                      <div className="h5">Bentex</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                  <div className="card-body">
                  <div className="h5">Senior Software Developer</div>
                  <ul style={{paddingLeft: "25px"}}>
    
<li>   Created a program that runs different processes for different jobs at scheduled times set per User UI Dashboard.</li>
<li> Maintained a highly developed Web Application that tracks inventory, orders, and listings built in Razor and C#.</li>

<li>Developed hundreds of rational databases , DataTables, Views, Indexes, and Stored Procedures using Sql Server Management studio.</li>

<li>Used Entity Framework to connect , update, delete, and insert into our different databases.</li>
<li>Built an Application to Track. Update, and Publish our listings to Amazon, Target, Walmart, and other marketplaces.</li>
<li>Set up daily database backups using scripts and set up other scheduled processes such as emailers and reports.</li>
<li>Designed Servers to be virtually accessed through Remote Desktop, running Sql Server, and IIS Applications.</li>
<li>Developed a web app made with VB, StoredProcedures, and windows forms for 3 divisions within our company to track factory changes, factory orders, and factory management.</li>
     
       </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
              <div className="row no-gutters">
                <div className="col-md-3">
                  <img src={require('./Images/fm.png')} style={{ marginTop: "35%"}} width="50%" height="50%" className="card-img" alt="..."/>
                  <div className="card-img-overlay cc-experience-header d-flex">
                    <div className="my-auto mx-auto text-center">
                      <p>March 2020 - March2022</p>
                      <div className="h5">FM Home Loans</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                  <div className="card-body">
                  <div className="h5">Senior Software Developer</div>
                    <ul style={{paddingLeft: "25px"}}>
                   <li> Created Azure Functions and Logic Apps for tens of business time-based operations http
webhooks for third party integrated services. Utilized cloud services for event/queue
based operations and triggers.</li>
<li> Developed Web api's with the language C# and .Net Core using the S.O.L.I.D coding
guidelines.</li>
<li>Designed and developed a document management service for automatically merging
hundreds of documents with loan data daily and manages the e-signature process using a
third-party service.</li>
<li>Took part in developing an internal web application using React, Azure AD
authentication and utilizing the Microsoft’s Graph API to integrate with One Drive,
Outlook and other Microsoft services.</li>
<li>Developed SQL schemas including stored procedures, functions, triggers and views
using Entity Framework with .Net Core.</li>
<li>Set up daily database backups using scripts and set up other scheduled processes such as
emailers and reports.</li>
<li>Developed a web app for taking and managing loan applications using React, Redux,
Router and other libraries for the frontend with CI/CD set up deploying to Azure blob
storage, Azure Functions for the backend with Azure Front Doors service for our proxy
router.</li>
<li>Integrated Auth0 as our identity provider with JWT token on the client app for the login
and on the server to authenticate and authorize requests. Set up on Auth0 custom rules,
templates, login pages, and enterprise connection for logging in using Azure AD.</li>
       </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
              <div className="row no-gutters">
                <div className="col-md-3">
                  <img src={require('./Images/livecare.jpg')} width="50%" height="100%" className="card-img" alt="..."/>
                  <div className="card-img-overlay cc-experience-header d-flex">
                    <div className="my-auto mx-auto text-center">
                      <p>February 2019 - December 2019</p>
                      <div className="h5">LiveCare Corp.</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                  <div className="card-body">
                  <div className="h5">iOS/Android Developer</div>
                    <ul style={{paddingLeft: "25px"}}>
                      <li>Created, Managed, Designed and Released IOS application from scratch.</li>
                      <li>Debugged, Released, Designed, Refactored code for Android application</li>
                      <li>Used Twilio API to send and receive Voice calls , video calls, and texts</li>
                      <li>Integrated Firebase Authentication, Hosting, Cloud Functions, and FCM with all projects</li>
                      <li>Developed Scripts in Node and Java SpringBoot for backend http requests, using AWS</li>
                      <li>Authenticated Users with Firebase Authentication</li>
                      <li>Implemented Bluetooth into android apps to connect to health monitors to read, write, and scan data</li>
                      <li>Constructed apps using React-Native and Flutter</li>
                      <li>Migrated classes on an iOS app from objective C code to Swift code</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
              <div className="row no-gutters">
                <div className="col-md-3">
                  <img src={require('./Images/comp.jpg')} width="50%" height="100%" className="card-img" alt="..."/>
                  <div className="card-img-overlay cc-experience-header d-flex">
                    <div className="my-auto mx-auto text-center">
                      <p>June 2018 - November 2018</p>
                      <div className="h5">JComputers</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                  <div className="card-body">
                    <div className="h5">IT Professional</div>
                    <ul style={{paddingLeft: "25px"}}>
                      
                      <li>Provide technical support and assistance to end-users in person, over the phone, or via remote access</li>
                      <li>Maintain and troubleshoot hardware and software, including servers, workstations, printers, and mobile devices</li>
                      <li>Install and configure new hardware and software, ensuring compatibility and proper functionality</li>
                      <li>Develop and implement IT policies and procedures to ensure efficient and effective use of technology resources</li>
                      <li>Manage and maintain network infrastructure, including routers, switches, firewalls, and wireless access points</li>
                      <li>Conduct regular backups and disaster recovery testing to ensure data and system availability</li>
                      <li>Monitor system performance and proactively identify and resolve issues before they impact end-users</li>
                      <li>Conduct security audits and implement measures to protect company data and systems from potential threats</li>
                      <li>tay up-to-date with emerging trends and technologies in the field of information technology and make recommendations for system improvements</li>
                      <li>Collaborate with cross-functional teams to analyze business requirements and translate them into technical specifications</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
              <div className="row no-gutters">
                <div className="col-md-3">
                  <img src={require('./Images/steven-chera-scc-2.jpg')} width="50%" height="50%" className="card-img" alt="..."/>
                  <div className="card-img-overlay cc-experience-header d-flex">
                    <div className="my-auto mx-auto text-center">
                      <p>September 2015 - June 2018</p>
                      <div className="h5">Sephardic Community Center</div>
                    </div>
                  </div>
                </div>
                <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                  <div className="card-body">
                    <div className="h5">Web/Mobile Developer</div>
                    <ul style={{paddingLeft: "25px"}}>
                      <li>Wrote programs in C++ to handle specific jobs such as tracking patients, storing or retrieving data, or controlling equipment.</li>
                      <li>Designed and developed user-friendly website, including optimized check-out page that improved ways for members to enroll in a program.</li>
                      <li>Created mobile app using android studio, and java to allow staff users to manage ongoing programs at the facility.</li>
                      <li>Built mobile application using Xcode, and swift to allow staff users to manage ongoing programs at the facility.</li>
                      <li>Used IOS frameworks such as Cocoa, CocoaTouch, Alamofire, UiKit plus more.</li>
                      <li>Implemented MySQL into mobile apps.</li>
                      <li>Updated both the website and mobile apps frequently with new UI and functionality.</li>
                      <li>Built Backend Api's with Java SpringBoot.</li>
                      <li>Have used GIT to manage versions of the iOS,Android, and web applications.</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

           

          </div>
        </div>
      </div>
    </div>
  );
}
export default Experience;
