import React, { Fragment, Component } from "react";
import { Route, Routes, Navigate, BrowserRouter as Router } from "react-router-dom";
import 'video-react/dist/video-react.css';
import './App.css';

// Components
import MainNavigation from "./components/Navigation/MainNavigation";
import MainHeader from "./components/Main Page/MainHeader";
import AboutMe from "./components/Main Page/AboutMe";
import RecentProjects from "./components/Main Page/Project";
import ContactForm from "./components/Main Page/ContactMe/contact";
import ResumeNavigation from "./components/Navigation/ResumeNavigation";
import ResumeHeader from "./components/Resume Page/ResumeHeader";
import Education from "./components/Resume Page/education";
import Experience from "./components/Resume Page/experience";
import Skills from "./components/Resume Page/skills";
import Awards from "./components/Resume Page/awards";
import ProjectHeader from "./components/Projects Page/ProjectHeader";
import NYU from "./components/Projects Page/AllProjects";
import Footer from "./components/Footer";
import ScrollTop from "./components/ScrollTop";

// Pages
import Watermark from "./pages/ProjectPages/watermark";
import SubmitPage from "./pages/submit";
import SubscribedPage from "./pages/subscribe";
import ComingSoonPage from "./pages/comingsoon";
import NotFoundPage from "./pages/404";
import Test from "./pages/test";

const Main = () => (
  <Fragment>
    <MainNavigation />
    <MainHeader />
    <AboutMe />
    <RecentProjects />
    <ContactForm />
    <Footer />
  </Fragment>
);

const Submit = () => (
  <Fragment>
    <SubmitPage />
    <Footer />
  </Fragment>
);

const Subscribed = () => (
  <Fragment>
    <SubscribedPage />
    <Footer />
  </Fragment>
);

const ResumePage = () => (
  <Fragment>
    <ResumeNavigation />
    <ResumeHeader />
    <Experience />
    <Skills />
    <Education />
    <Awards />
    <Footer />
  </Fragment>
);

const ProjectsPage = () => (
  <Fragment>
    <ProjectHeader />
    <NYU />
    <Footer />
  </Fragment>
);

const WatermarkPage = () => (
  <Fragment>
    <Watermark />
    <Footer />
  </Fragment>
);

const ComingSoon = () => (
  <Fragment>
    <ComingSoonPage />
    <Footer />
  </Fragment>
);

const NotFound = () => (
  <Fragment>
    <NotFoundPage />
    <Footer />
  </Fragment>
);

const TestPage = () => (
  <Fragment>
    <Test />
    <Footer />
  </Fragment>
);

class App extends Component {
  render() {
    return (
      <Router>
        <ScrollTop />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/submit" element={<Submit />} />
          <Route path="/subscribed" element={<Subscribed />} />
          <Route path="/resume" element={<ResumePage />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/projects/watermark" element={<WatermarkPage />} />
          <Route path="/comingsoon" element={<ComingSoon />} />
          <Route path="/404" element={<NotFound />} />
          <Route path="/test" element={<TestPage />} />
          {/* Catch-all route to 404 page */}
          <Route path="*" element={<Navigate to="/404" />} />
        </Routes>
      </Router>
    );
  }
}

export default App;
