import React from "react";
import AOS from 'aos';
import "bootstrap/dist/js/bootstrap.bundle.min";
import './experience.css';
import './main.css';
import './aos.css';
import './aboutStyleCSS.css';
import './bootstrap.min.css';

AOS.init();

const Education = () => {
    return (
      <div>
        <div className="page-content" id="education"> 
          <div className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
            <div className="container cc-education">
              <h5 className="display-4 mb-5 text-center">Education</h5>
              <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
                <div className="row no-gutters">
                  <div className="col-md-3">
                    <img src={require('./Images/nycct2.jpeg')} width="80%" height="80%" className="card-img" alt="..."/>
                    <div className="card-img-overlay cc-education-header d-flex">
                      <div className="my-auto mx-auto text-center">
                        <p>2017 - 2019</p>
                        <div className="h5">B.S. - Computer Information Systems</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                    <div className="card-body">
                    <div className="h5">City University of New York - NYCCT (CUNY)</div>
                      <p className="category">3.8 GPA</p>
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Major: Computer Information Systems</li>
                        <li>Honors: Cuny Emerging Scholars, National Society of Collegiate Scholars,Dean's List 2017 - 2019</li>
                        <li>
                          Relevent Coursework:
                          <ul style={{paddingLeft: "25px"}}>
                            <li>Computer Science & Engineering: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li> Discrete Structures, Computer Architecture, Design & Analysis of Algorithms, Databases, Computer Networking,
                                     Machine Learning, Backend Developement with Springboot, Mobile Development with React-Native.
                                </li>
                              </ul>
                            </li>
                            
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
                <div className="row no-gutters">
                  <div className="col-md-3">
                    <img src={require('./Images/nycct1.png')} style={{paddingTop: "70px"}}width="80%" height="80%" className="card-img" alt="..."/>
                    <div className="card-img-overlay cc-education-header d-flex">
                      <div className="my-auto mx-auto text-center">
                        <p>2015 - 2017</p>
                        <div className="h5">A.A.S. - Computer Systems Technology</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                    <div className="card-body">
                    <div className="h5">City University of New York - NYCCT (CUNY)</div>
                      <p className="category">3.9 GPA</p>
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Major: Computer Systems Technology</li>
                        <li>Honors: Dean's List, Hillel Leadership Award, Emerging Scholors Awards</li>
                        <li> Relevent Coursework:
                          <ul style={{paddingLeft: "25px"}}>
                            <li>Computer Science & Engineering: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Java Programming, Web Programming, C# Programming, Mobile Development with Swift and Java, C++ Programming, Object Oriented Programming, Data Structures
                                </li>
                              </ul>
                            </li>
                            <li>Mathematics: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Calculus, Differential Equations, Linear Algebra, Discrete Algorithms 1 & 2</li>
                              </ul>
                            </li>
                            <li>Other: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>English Composition 1 & 2, Music History, Psychology</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card mb-3" style={{maxWidth: "1138px"}} data-aos="fade-right" data-aos-offset={0} data-aos-duration={500}>
                <div className="row no-gutters">
                  <div className="col-md-3">
                    <img src={require('./Images/or.jpg')} style={{paddingTop: "70px"}} className="card-img" alt="..."/>
                    <div className="card-img-overlay cc-education-header d-flex">
                      <div className="my-auto mx-auto text-center">
                        <p>2011 - 2015</p>
                        <div className="h5">Ohr Hatorah High School</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9" data-aos="fade-left" data-aos-offset={50} data-aos-duration={500}>
                    <div className="card-body">
                    <div className="h5">Ohr Hatorah High School</div>
                      <p className="category">3.81 GPA</p>
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Major: Computer Science</li>
                        <li>Honors: School Service Award, Advanced Regents Diploma</li>
                        <li>Standardized Tests: SAT</li>
                        <li>Regents: 
                          <ul style={{paddingLeft: "25px"}}>
                            <li>Algebra, Geometry, Trigonometry, Earth Science, Biology, Global History, US History, Hebrew, English</li>
                          </ul>
                        </li>
                        <li>Relevent Coursework:
                          <ul style={{paddingLeft: "25px"}}>
                            <li>Introductory Coding Class </li>
                            <li>Math/Science: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Algebra, Geometry, Trigonometry, Calculus 1 (College Course - University At Albany)</li>
                                <li>Earth Science, Biology, Chemistry, Physics</li>
                              </ul>
                            </li>
                            <li>History/Languages: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Global History, U.S. History</li>
                                <li>English, Hebrew</li>
                              </ul>
                            </li>
                            <li>Other: 
                              <ul style={{paddingLeft: "25px"}}>
                                <li>Micro-Economics (College Course - Touro College)</li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
export default Education;