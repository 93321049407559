import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Typist from "react-typist";
import Configs from "../../editable-stuff/configurations.json";

const Watermark = () => {
  const icons = Configs.icons;

  // const [backgroundType, setBackgroundType] = useState(Configs.backgroundType);
  const [hoverstatus, setHoverstatus] = useState([
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons",
    "socialicons"
  ]);

  const toggleHover = data => {
    const newhoverStatus = [...hoverstatus];

    if (data.event === "enter") {
      newhoverStatus[data.icon.id] = "socialiconshover";
      return setHoverstatus(newhoverStatus);
    } else if (data.event === "leave") {
      newhoverStatus[data.icon.id] = "socialicons";
      return setHoverstatus(newhoverStatus);
    }
  };
  return (
    <div> 
      <div
        id="WatermarkHeader"
        className="jumbotron jumbotron-fluid bg-transparent bgstyle text-light min-vh-auto d-flex align-content-center align-items-center flex-wrap m-0"
      >
        <div className="container container-fluid text-center ">
          <h1 className="display-3">Watermark Adder</h1>
          <Typist className="lead" cursor={{ hideWhenDone: true }}>
          Take a directory of CAD drawing, add a specified watermark to it, save the drawing (as original name) and close it.</Typist>
          <div  style={{paddingTop: "20px"}}>
            <a
              className="btn btn-outline-light btn-lg"
              href="https://github.com/ourialkada/WatermarkAdder"
              target="_blank"
              rel="noreferrer noopener"
              role="button"
              aria-label="Resume/CV"
            >
              Github Page
            </a>
          </div>
          <div className="p-6" style={{paddingTop:"20px", paddingBottom:"40px"}}>
            {icons.map(icon => (
              <a
                key={icon.id}
                target="_blank"
                rel="noopener noreferrer"
                href={icon.url}
                aria-label={`My ${icon.image.split("-")[1]}`}
              >
                <i
                  className={`fab ${icon.image}  fa-3x ${hoverstatus[icon.id]}`}
                  onMouseOver={() => toggleHover({ icon, event: "enter" })}
                  onMouseOut={() => toggleHover({ icon, event: "leave" })}
                />
              </a>
            ))}
          </div>
          <Link className="btn btn-outline-light btn-lg" role="button" to="/">Home</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/resume">Resume</Link>
          &nbsp;&nbsp;&nbsp;
          <Link className="btn btn-outline-light btn-lg" role="button" to="/projects">Projects</Link>
           </div>
      </div>
      <div id="Watermark" className="jumbotron jumbotron-fluid bg-transparent min-vh-100 m-0">
      <div className="container container-fluid p-5">
          <div className="accordion" id="WatermarkAccordian">
            <div className="card">
              <div className="card-header" id="WatermarkOverview">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" style={{color:"black", fontWeight:"bold"}}>
                    Project Overview
                  </button>
                </h2>
              </div>

              <div id="collapseOne" className="collapse show" aria-labelledby="WatermarkOverview" data-parent="#WatermarkAccordian">
                <div className="card-body">
                At Con Edison, there were many times where they received drawings from contractors, that need to be marked with a confidential classification. 
                The classification was usually placed by means of a watermark. To manually watermark 1000s of drawings, would in itself be a full time job. 
                The project was to create a program to do this using VBA and AutoCAD API. I created a tool where the user would specify the directory of files they would like to process. 
                The program will copy the directory to the server, add a new layer with the watermark, save the file as its original name, 
                send an email to the user saying the files have been processed with a link to the files. After the drawings have been processed, 
                the files will be deleted after 7 days as set by the admin to avoid overloading the server. The challenge was that it had to be a tool that anyone can access, 
                without having to download on their machine due to the limitations set by Con Edison, where only an admin can download any executables. 
                The solution was to place this program and processed in the background on a server, having a File Listener. 
                The File Listener would listen for any directories added in a certain location, and it will begin the process notifying the user when it is complete.
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="WatermarkGoals">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo" style={{color:"black", fontWeight:"bold"}}>
                    Project Goals
                  </button>
                </h2>
              </div>
              <div id="collapseTwo" className="collapse show" aria-labelledby="WatermarkGoals" data-parent="#WatermarkAccordian">
                <div className="card-body">
                  <ul style={{paddingLeft: "25px"}}>
                    <li>Fully designed and implemented by me.</li>
                    <li>Weekly meetings with manager showing progress for the week.</li>
                    <li>Obtaining admin approval to create the executable.</li>
                    <li>Obtaining a server to run the program on.</li>
                    <li>Obtaining a no-reply email to be used to let user track progress.</li>
                    <li>Have the ability to add a watermark to many DWG files.</li>
                    <li>Making it as convenient and as quick as possible for the user.</li>
                    <li>Client Side:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>User will specify the directory they would like to process.</li>
                        <li>The directory will be copied to the server for processing.</li>
                        <li>No additional downloads for the user.</li>
                        <li>The user will receive an email with a link to the processed files.</li>
                      </ul>
                    </li>
                    <li>Server Side:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>There is a File Listener, watching for a new directory.</li>
                        <li>When it sees a new directory, it will open a process of AutoCAD with an Embedded Drawing.</li>
                        <li>The drawings will be processed and stored on the server.</li>
                        <li>An email will be sent to the user with a link to the files.</li>
                        <li>The files will be deleted after 7 days as specified by the admin.</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="WatermarkGallery">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive" style={{color:"black", fontWeight:"bold"}}>
                    Gallery
                  </button>
                </h2>
              </div>
              <div id="collapseFive" className="collapse show" aria-labelledby="WatermarkGallery" data-parent="#WatermarkAccordian">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="embed-responsive embed-responsive-1by1">
                        <iframe title="Testing" className="embed-responsive-item" src='https://player.vimeo.com/video/' frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="embed-responsive embed-responsive-1by1">
                        <iframe title="Presentation" className="embed-responsive-item" src='https://player.vimeo.com/video/' frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="card">
              <div className="card-header" id="WatermarkUsage">
                <h2 className="mb-0">
                  <button className="btn btn-lg btn-link" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour" style={{color:"black", fontWeight:"bold"}}>
                    Skills Used
                  </button>
                </h2>
              </div>
              <div id="collapseFour" className="collapse show" aria-labelledby="WatermarkUsage" data-parent="#WatermarkAccordian">
                <div className="card-body">
                  <ul style={{paddingLeft: "25px"}}>
                  <li>Software:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>Visual Studio</li>
                        <li>AutoCAD</li>
                        <li>AutoCAD API</li>
                      </ul>
                    </li>
                    <li>Languages:
                      <ul style={{paddingLeft: "25px"}}>
                        <li>VBA</li>
                        <li>C#</li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Watermark;